<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent="submit">
          <v-layout column class="border-primary rounded-lg px-8 pt-4">
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("full_name") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="textRequired"
                  vid="full_name"
                  :rules="requiredRules"
                >
                  <v-text-field
                    v-model="fullName"
                    outlined
                    dense
                    class="rounded-lg"
                    :maxlength="maximumSmallText"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.employee_fullname')"
                  />
                </validation-provider>
              </v-layout>
              <v-layout column col-6 class="pl-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("phone") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="phone"
                  :rules="phoneRules"
                >
                  <v-text-field
                    v-model="phone"
                    outlined
                    dense
                    filled
                    disabled
                    class="rounded-lg"
                    :maxlength="maximumPhone"
                    :error-messages="errors"
                    :placeholder="$t('place_holders.phone')"
                    @keypress="isNumber($event)"
                  />
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout>
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("email") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <v-text-field
                  v-model="email"
                  outlined
                  dense
                  disabled
                  filled
                  class="rounded-lg"
                />
              </v-layout>
              <v-layout column col-6 class="pl-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("regency") }}
                  <span class="red--text">{{ $t("required") }}</span>
                </label>
                <validation-provider
                  v-slot="{ errors }"
                  name="selectRequire"
                  vid="regency"
                  :rules="requiredRules"
                >
                  <v-select
                    v-model="regencySelected"
                    outlined
                    dense
                    multiple
                    chips
                    class="rounded-lg"
                    item-text="name"
                    item-value="code"
                    :placeholder="$t('place_holders.regency')"
                    :items="regencyItems"
                    :error-messages="errors"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip color="#CCF3FE">
                        {{ item.name }}
                      </v-chip>
                    </template>
                  </v-select>
                </validation-provider>
              </v-layout>
            </v-layout>
            <v-layout v-if="code">
              <!--code-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("employee_code") }}
                </label>
                <v-text-field
                  v-model="code"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumEmail"
                  :placeholder="$t('place_holders.employee_code')"
                />
              </v-layout>
              <v-layout column col-6 class="pl-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("post_offices") }}
                </label>
                <v-text-field
                  v-model="postOffice"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumEmail"
                  :placeholder="$t('place_holders.employee_code')"
                />
              </v-layout>
            </v-layout>
            <v-layout v-if="code">
              <!--code-->
              <v-layout column col-6 class="pr-6 py-0">
                <label class="text-subtitle-1">
                  {{ $t("status") }}
                </label>
                <v-text-field
                  v-model="status"
                  outlined
                  dense
                  filled
                  disabled
                  class="rounded-lg"
                  :maxlength="maximumEmail"
                  :placeholder="$t('place_holders.employee_code')"
                />
              </v-layout>
            </v-layout>
            <v-layout v-if="listShop.length">
              <div>
                {{ $t("list-main-shop") }}
              </div>
            </v-layout>
            <div v-if="listShop.length" class="mb-2 box-list-content-order">
              <v-data-table
                class="border-primary"
                fixed-header
                height="35vh"
                style="overflow: auto"
                :no-data-text="$t('not_found')"
                hide-default-footer
                :headers="headers"
                :items="listShop"
                :items-per-page="listShop.length"
              >
                <template v-slot:[`item.stt`]="{ index }">
                  <td class="max-width-3 text-truncate">
                    {{ index + 1 }}
                  </td>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <tr class="max-width-3 text-truncate">
                    {{ item.name }}
                  </tr>
                </template>
                <template v-slot:[`item.phone`]="{ item }">
                  <tr class="max-width-3 text-truncate">
                    {{ item.phone }}
                  </tr>
                </template>
                <template v-slot:[`item.email`]="{ item }">
                  <tr class="max-width-3 text-truncate">
                    {{ item.email }}
                  </tr>
                </template>
                <template v-slot:[`item.addressDetail`]="{ item }">
                  <tr class="max-width-3 text-truncate">
                    {{ item.provinceName }}
                  </tr>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <tr class="max-width-3 text-truncate">
                    {{ formatDateView(item.createdAt) }}
                  </tr>
                </template>
              </v-data-table>
            </div>
          </v-layout>
          <v-layout v-if="checkRoleAction" justify-end class="mt-4">
            <v-btn
              v-if="disabledLock"
              outlined
              dense
              color="primary"
              class="rounded-lg mr-4"
              @click="onShowConfirmDelete"
            >
              {{ $t("lock") }}
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              class="rounded-lg"
              :disabled="invalid || disabledBtn"
            >
              {{ $t("save") }}
            </v-btn>
          </v-layout>
        </v-form>
      </validation-observer>

      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :persistent="persistent"
        :icon-src="getIcon"
        :message="message"
      />

      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />

      <!-- dialog confirm del -->
      <dialog-confirm
        v-model="showConfirmDel"
        :icon-src="getIcon"
        :message="message"
        :text-cancel="textCancel"
        :text-accept="textAccept"
        :on-cancel="onCancelConfirmDel"
        :on-accept="onAcceptConfirmDel"
      />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from '@/components/base/Breadscrumbs'
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import constants from "@/constants";
import { DateTimeUtils } from "@/helpers/dateTimeUtils";
import { StringUtils } from "@/helpers/stringUtils";
import { FormUtils } from "@/helpers/formUtils";
import routePaths from "@/router/routePaths";
import { StorageService } from "@/services/storageService";
import { UserService } from "@/services/userService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    DialogConfirm,
    Breadscrumbs
  },
  data() {
    return {
      constants,
      id: 0,
      detail: null,
      requiredRules: {
        required: true,
      },
      fullName: "",
      maximumSmallText: constants.maximumSmallText,
      code: "",
      phone: "",
      maximumPhone: constants.maximumPhone,
      phoneRules: {
        required: true,
        min: constants.minimumPhone,
        max: constants.maximumPhone,
        regex: constants.regExp.phone,
      },
      email: "",
      maximumEmail: constants.maximumEmail,
      emailRules: {
        required: true,
        email: true,
        min: constants.minimumEmail,
        max: constants.maximumEmail,
        regex: constants.regExp.email,
      },
      regencySelected: "",
      regencyItems: [],
      codeEmployee: '',

      showNoti: false,
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      message: "",
      showLoading: false,
      showConfirmTokenExpried: false,

      showConfirmDel: false,
      textCancel: this.$t("cancel"),
      textAccept: this.$t("lock"),
      headers: [
        {
          text: this.$t("stt"),
          value: "stt",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("customer_name"),
          value: "name",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("phone"),
          sortable: false,
          value: "phone",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("email"),
          value: "email",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("address"),
          value: "provinceName",
          class: "text-subtitle-2",
        },
        {
          text: this.$t("create-date"),
          value: "createdAt",
          class: "text-subtitle-2",
        },
      ],
      listShop: [],
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      checkShowTable: false,
      breadcrumbs: [
        {
          text: 'employee_list',
          disabled: false,
          isActive: false,
          href: routePaths.EMPLOYEES
        },
        {
          text: 'employee_detail',
          disabled: true,
          isActive: true,
        },
      ],
      postOffice: null,
      disabledLock: false,
      status: '',
      disabledBtn: false
    };
  },
  computed: {
    checkRoleAction() {
      return this.currentRole.includes("ROLE_ADMIN") || this.currentRole.includes("ROLE_ROOT")
    },
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti);
    },
    isPermissionAction() {
      const currUser = StorageService.getCurrentUser();
      const userRoles = currUser.roles;
      return (
        userRoles.includes(constants.roleRoot) &&
        parseFloat(StorageService.getUserId()) !== parseFloat(this.id)
      );
    },
    isDisabled() {
      const currUser = StorageService.getCurrentUser();
      const userRoles = currUser.roles;
      return (
        !userRoles.includes(constants.roleRoot) ||
        parseFloat(StorageService.getUserId()) === parseFloat(this.id)
      );
    },
  },
  created() {
    this.id = this.$route.query.id;
    if (window.location.pathname && !this.currentRole.includes("ROLE_ADMIN")) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getRoles();
      if (this.id) {
        await this.getEmployeeInfo();
      } else {
        this.$router.push(routePaths.EMPLOYEES);
      }
      this.getShopsOfSale()
    },
    async submit() {
      var valid = this.$refs.observer.validate();
      if (valid) {
        if (parseInt(this.id) === parseInt(StorageService.getUserId())) {
          this.toggleDialogNoti({
            state: true,
            msg: this.$t("not_update_self"),
          });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        } else {
          this.disabledBtn = true
          this.showLoading = true;
          const pars = this.bindRequestPars();
          const { status, data } = await UserService.employeeUpdate(pars);
          this.showLoading = false;
          if (status === constants.statusCode.ok && data) {
            this.toggleDialogNoti({
              state: true,
              persistent: true,
              type: constants.typeAlert.success,
              msg: this.$t("success_saved_info"),
            });
            setTimeout(() => {
              this.$router.push(routePaths.EMPLOYEES);
            }, constants.timeOut);
          } else {
            this.toggleDialogNoti({ state: true, msg: data.message });
            setTimeout(() => {
              this.toggleDialogNoti();
            }, constants.timeOut);
            this.disabledBtn = false
          }
        }
      }
    },
    bindRequestPars() {
      const pars = {
        id: this.id,
        name: this.fullName.trim(),
        code: this.code ? this.code.trim() : null,
        roles: this.regencySelected,
      };

      return pars;
    },
    async getShopsOfSale() {
      const { status, data } = await UserService.getShopsOfSale(this.id);
      if (status === constants.statusCode.ok && data) {
        this.listShop = data;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    formatDateView(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDate);
    },
    async getEmployeeInfo() {
      const pars = {
        usersId: this.id,
      };
      if (this.currentRole.includes("ROLE_ADMIN")) {
        this.showLoading = true;
        const { status, data } = await UserService.employeeGetInfo(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok && data) {
          this.detail = data;
          this.getTextStatus(data.status)
          if (data.status === 1) {
            this.disabledLock = true
          } else {
            this.disabledLock = false
          }
          this.detail.roles.forEach((roles) => {
            if (roles === 'ROLE_ADMIN' || roles === 'ROLE_CUSTOMER_CARE' || roles === 'ROLE_ACCOUNTANT' || roles === 'ROLE_ROOT') {
              this.checkShowTable = true;
            }
          })
          this.parseFormData(data);
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.$router.go(-1);
          }, constants.timeOutRole);
        }
      }
    },
    isPermissionViewDetail(item) {
      if (item.roles) {
        const currUser = StorageService.getCurrentUser();
        const userRoles = currUser.roles;
        let isAccept = false;
        userRoles.forEach((i) => {
          item.roles.forEach((j) => {
            if (i === j) {
              isAccept = true;
            }
          });
        });

        return isAccept;
      }

      return false;
    },
    async parseFormData(data) {
      this.fullName = data.name;
      this.code = data.code;
      this.phone = data.phone;
      this.regencySelected = data.roles;
      this.email = this.getTextTruncate(data.email, 50)
      this.postOffice = data.poName
    },
    async getRoles() {
      this.showLoading = true;
      const { status, data } = await UserService.getRoles();
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.regencyItems = data;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    onShowConfirmDelete() {
      this.message = this.$t("lock_this_employee");
      this.typeNoti = constants.typeAlert.warning;
      this.showConfirmDel = true;
    },
    onCancelConfirmDel() {
      this.showConfirmDel = false;
      this.message = "";
    },
    async onAcceptConfirmDel() {
      this.showConfirmDel = false;
      if (this.detail) {
        this.showLoading = true;
        const pars = { id: this.detail.id };
        const { status, data } = await UserService.employeeDelete(pars);
        this.showLoading = false;
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            type: constants.typeAlert.success,
            msg: this.$t("success_employee_lock"),
          });
          setTimeout(() => {
            this.$router.push(routePaths.EMPLOYEES);
          }, constants.timeOut);
        } else if (StorageService.checkResponseExpried(status, data)) {
          this.showConfirmTokenExpried = true;
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message });
          setTimeout(() => {
            this.toggleDialogNoti();
          }, constants.timeOut);
        }
      }
    },
    getTextStatus(item) {
      if (item === constants.statusCustomer.active) {
        this.status = this.$t("actived");
      } else if (item === constants.statusCustomer.delete) {
        this.status = this.$t("locked");
      } else if (item === constants.statusCustomer.inactive) {
        this.status = this.$t("off");
      }
    },
    getTextTruncate(val, size) {
      if (val.length > size) {
        return val.substring(0, size) + "...";
      } else {
        return val;
      }
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt);
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
  },
};
</script>
<style src="@/styles/postOffice.scss" lang="scss">
</style>
